.container {
  @apply pt-4 space-y-2 text-sm;
}

.container > * {
  @apply space-y-2;
}

.container ul,
.container ol {
  list-style-type: decimal;
  list-style-position: outside;
}

.container li {
  @apply pl-1;
}
