.button {
  @apply font-medium px-4 py-3 rounded disabled:opacity-25 disabled:pointer-events-none;
}

.default,
.default[type='button'],
.default[type='submit'],
.default[type='reset'] {
  @apply bg-primary hover:bg-primary-dark;
}
.default.light,
.default.light[type='button'],
.default.light[type='submit'],
.default.light[type='reset'] {
  @apply bg-grey-2 hover:bg-grey;
}

.default.secondary,
.default.secondary[type='button'],
.default.secondary[type='submit'],
.default.secondary[type='reset'] {
  @apply bg-secondary text-white hover:bg-secondary-dark;
}

.default.gradient,
.default.gradient[type='button'],
.default.gradient[type='submit'],
.default.gradient[type='reset'] {
  @apply bg-gradient-to-r from-primary to-secondary text-white hover:bg-secondary-dark;
}

.default.success,
.default.success[type='button'],
.default.success[type='submit'],
.default.success[type='reset'] {
  @apply bg-success text-white hover:bg-success-dark;
}

.default.blue,
.default.blue[type='button'],
.default.blue[type='submit'],
.default.blue[type='reset'] {
  @apply bg-blue text-white hover:bg-blue-dark;
}

.default.small,
.default.small[type='button'],
.default.small[type='submit'],
.default.small[type='reset'] {
  @apply px-3 py-[0.4375rem] text-xs rounded-[15px];
}

.default.medium,
.default.medium[type='button'],
.default.medium[type='submit'],
.default.medium[type='reset'] {
  @apply px-3 py-1.5 text-sm rounded-[5px];
}

.underline,
.underline[type='button'],
.underline[type='submit'],
.underline[type='reset'] {
  @apply font-semibold text-secondary;
  text-decoration: underline;
}

.outline,
.outline[type='button'],
.outline[type='submit'],
.outline[type='reset'] {
  @apply border border-primary text-primary hover:text-white hover:bg-primary;
}

.outline.secondary,
.outline.secondary[type='button'],
.outline.secondary[type='submit'],
.outline.secondary[type='reset'] {
  @apply border border-secondary text-secondary hover:text-white hover:bg-secondary;
}

.outline.white,
.outline.white[type='button'],
.outline.white[type='submit'],
.outline.white[type='reset'] {
  @apply border border-white text-white  hover:bg-transparent;
}

.outline.magic,
.outline.magic[type='button'],
.outline.magic[type='submit'],
.outline.magic[type='reset'] {
  @apply border border-magic text-magic  hover:bg-transparent;
}

.outline.small,
.outline.small[type='button'],
.outline.small[type='submit'],
.outline.small[type='reset'] {
  @apply px-3 py-[0.375rem] text-xs rounded-[15px];
}

.outline.medium,
.outline.medium[type='button'],
.outline.medium[type='submit'],
.outline.medium[type='reset'] {
  @apply px-3 py-1.5 text-sm rounded-[5px];
}
