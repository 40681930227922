.label {
  @apply flex cursor-pointer;
}

.label > input[type='radio'] {
  @apply hidden;
}

.label > input[type='radio'] + span {
  @apply relative border border-grey-2 rounded-full mr-2 flex-shrink-0;
  margin-top: 0.23rem;
  width: 12px;
  height: 12px;
}

.label > input[type='radio']:checked + span::after {
  content: '';
  width: 100%;
  height: 100%;
  /* top: 1px;
  left: 1px; */

  @apply absolute bg-secondary rounded-full;
}
