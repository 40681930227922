.box {
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 80vh;
  overflow: auto;
  @apply bg-white z-20 rounded-lg;
}

.box-content {
  @apply p-8 pt-0;
}
