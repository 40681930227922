.default-background {
  @apply bg-grey-2;
}

.background-image {
  @apply w-full;
  aspect-ratio: 1113 / 216;
}

.default-image {
  @apply bg-grey-2;
  aspect-ratio: 1 / 1;
}

.image {
  @apply border-2 border-white rounded-full w-[150px] h-[150px] max-[660px]:w-[80px] max-[660px]:h-[80px];
}
