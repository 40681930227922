.label {
  @apply flex cursor-pointer;
}

.label > input[type='checkbox'] {
  @apply hidden;
}

.label > input[type='checkbox'] + span {
  @apply relative border border-grey rounded-sm mr-3 flex-shrink-0 mt-1;
  width: 16px;
  height: 16px;
}

.label > input[type='checkbox']:checked + span::after {
  content: '';
  width: 8px;
  height: 8px;
  top: 3px;
  left: 3px;

  @apply absolute bg-primary rounded-sm;
}

.small.label {
  @apply text-sm;
}

.small.label > input[type='checkbox'] + span {
  @apply mr-2 mt-0.5;
}
