@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply text-font-black font-sans;
}

div.react-datepicker-time__input {
  @apply border border-secondary;
}

input.react-datepicker-time__input {
  @apply outline-none;
}
