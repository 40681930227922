.default-background {
  @apply bg-grey-2;
}

.background-image {
  @apply w-full cursor-pointer;
  aspect-ratio: 338 / 100;
}

.default-image {
  @apply bg-grey-2;
  aspect-ratio: 1 / 1;
}

.image {
  @apply border-2 border-white rounded-full w-[90px] h-[90px];
}
